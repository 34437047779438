<template>
    <div>
      <section v-if="errored">
        {{errInfo}}
      </section>

      <section v-else>
        {{ error }}

        {{ successMessage }}
        <div v-if="loading">Loading...</div>
        <div v-else v-for="quiz in allQuizzes" :key="quiz.id">
            <quiz
            :quiz="quiz"
            v-on:addQuizForBulkDelete="handleAddQuizForBulkDelete"
            v-on:removeQuizForBulkDelete="handleRemoveQuizForBulkDelete"
            />
        </div>

        <section>
          <button @click="bulkDeleteQuizzes(quizzesToDelete)" :disabled="quizzesToDelete.length === 0"> Delete All <span v-if="quizzesToDelete.length"> {{quizzesToDelete.length}} </span> </button>
        </section>

      </section>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Quiz from './components/Quiz.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('staging/quizzes');


export default {
  name: 'quizzes',
  components: {
    Quiz,
  },
  data() {
    return {
      error: '',
      quizzesToDelete: [],
      QUERY_ID_MAX: 10,
    };
  },
  methods: {
    ...mapActions(['getAllQuizzes', 'deleteQuiz']),
    handleAddQuizForBulkDelete(quizId) {
      this.quizzesToDelete.push(quizId);
    },
    handleRemoveQuizForBulkDelete(quizId) {
      const index = this.quizzesToDelete.indexOf(quizId);
      this.quizzesToDelete.splice(index, 1);
    },
    bulkDeleteQuizzes(quizArray) {
      const arr = quizArray;
      const newArr = [];

      for (let i = 0, k = -1; i < arr.length; i++) {
        if (i % this.QUERY_ID_MAX === 0) {
          k += 1;
          newArr[k] = [];
        }
        newArr[k].push(arr[i]);
      }
      newArr.forEach((quizSubArray) => {
        const quizSubArrayIds = quizSubArray.join(',');
        this.deleteQuiz(quizSubArrayIds);
      });
    },
  },
  computed: {
    ...mapGetters(['allQuizzes', 'errInfo', 'errored', 'loading', 'successMessage']),
  },
  created() {
    if (!this.allQuizzes || (this.allQuizzes.length === 0)) this.getAllQuizzes();
  },
};
</script>

<style>

</style>

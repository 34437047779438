<template>
  <div>
    <div class="question_card">
      <span class="bulk_delete_quiz_span">
        <input
        type="checkbox"
        v-on:click="emitToQuizzes($event, quiz.id)"
        :value="quiz.id"
        />
      </span>
      <div v-html="quiz.question"></div>
      <div class="options_bracket">
        <div v-for="(quizOption, index) in quiz.options" :key="index">
          <div class="option">
            <label class="">
              <input type="radio" v-model="selected" :value="quizOption" :id="index">
              <span class="label-body">{{quizOption}}</span>
            </label>
          </div>
        </div>
        <span @click="handleEdit(quiz.id)" class="edit-qst"><PencilIcon /></span>
        <span @click="handleDelete(quiz.id)" class="del-qst"><DeleteIcon /></span>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteIcon from 'mdi-vue/Delete';
import PencilIcon from 'mdi-vue/Pencil';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('staging/quizzes');
export default {
  components: {
    DeleteIcon,
    PencilIcon,
  },
  data() {
    return {
      selected: '',
    };
  },
  props: {
    quiz: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions(['deleteQuiz', 'setQuizToEdit']),
    handleDelete(quizId) {
      if (quizId) {
        this.deleteQuiz(quizId);
      }
    },
    handleEdit(quizId) {
      this.setQuizToEdit(quizId);
      this.$router.push(`/book/${quizId}/quiz/edit`);
    },
    emitToQuizzes(e, quizId) {
      if (e.target.checked) {
        this.$emit('addQuizForBulkDelete', quizId);
      } else {
        this.$emit('removeQuizForBulkDelete', quizId);
      }
    },
  },
};
</script>

<style lang='stylus'>
  .question_card
    position: relative
    height: auto
    color: var(--color_on_primary)
    background-color: var(--color_primary)
    margin: 1rem
    padding: 1rem

    .options_bracket
      position: relative
      padding: 1rem 2rem

      .option
        &:hover
          cursor: pointer
          color: var(--color_primary_variant)
        .not_selected
          display: none
      .del-qst
        position: absolute;
        bottom: 0.1rem;
        right: 1rem;
        cursor: pointer;
      .edit-qst
        position: absolute;
        bottom: 0.1rem;
        left: 1rem;
        cursor: pointer;
    .bulk_delete_quiz_span
      position: absolute;
      top: 0.1rem;
      right: 1rem;
      cursor: pointer;
</style>
